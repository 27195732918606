import { CurrencyContext } from "context/CurrencyContext";
import { useContext } from "react";

function useCurrency() {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error(
      "useCurrency debe estar dentro de un proveedor AuthContext"
    );
  }
  return context;
}

export default useCurrency;
