import { useEffect, useState } from "react";
import axios from "axios";
import isPageWholesale from "utils/getSubdomain";

const monedas: any = {
  USD: 3500,
  EUR: 4000,
};

function useProduct(id: string, currency: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState();
  const isWholesale = isPageWholesale();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        let { data }: any = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/${id}`
        );
        const selectedPrice = data[isWholesale ? "priceWholesale" : "price"];
        const newPrice =
          currency === "COP"
            ? selectedPrice
            : Number(selectedPrice / monedas[currency]).toFixed(2);
        data = {
          ...data,
          images: data.images.map((img: any) => img.fileUrl),
          price: newPrice,
        };
        setProduct(data);
        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
        if (axios.isAxiosError(error)) {
          throw new Error(error.response?.data?.message, { cause: "axios" });
        } else {
          throw new Error(error.message, { cause: "function" });
        }
      }
    };

    if (!isCancelled && id !== undefined) fetchData();

    return () => {
      isCancelled = true;
    };
  }, [id, isWholesale, currency]);

  return {
    isLoading,
    product,
  };
}

export default useProduct;
