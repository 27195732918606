import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

const DEMO_DATA = [
  {
    name: "Descripción",
    content: "Somos fabricantes directos ofrecemos la mejor calidad y precio del mercado todos nuestros productos son 100% artesanales con ecxelentes acabados.",
  },
  {
    name: "Fabricación + Cuidado",
    content: `<ul class="list-disc list-inside leading-7">
      <li>Fabricados en bronce con baño de oro de 24 quilates para garantizar durabilidad y brillo.</li>
      <li>Evitar la aplicación directa de lociones, cremas, splash, etc., para mantener el acabado intacto.</li>
      <li>Después de su uso, limpiar con un paño suave para eliminar residuos y mantener la calidad.</li>
      <li>Guardar por separado en las bolsas de obsequio proporcionadas para evitar rayones y mantener el aspecto impecable.</li>
    </ul>`,
  },
  {
    name: "FAQ",
    content: `
    <ul class="list-disc list-inside leading-7">
      <li>
          <strong>¿Cuáles son sus métodos de pago aceptados?</strong>
          <p>Aceptamos transferencia bancaria por Bancolombia o Nequi, así como pagos contra entrega.</p>
      </li>
      <li>
          <strong>¿Cuánto tiempo tarda en llegar mi pedido?</strong>
          <p>El tiempo de entrega es de 2 a 3 días hábiles. Si te encuentras en Cali o Palmira, la entrega puede ser el mismo día o al día siguiente.</p>
      </li>
      <li>
          <strong>¿Ofrecen envíos internacionales?</strong>
          <p>Sí, contamos con convenio con DHL para envíos internacionales.</p>
      </li>
      <li>
          <strong>¿Cómo puedo hacer un seguimiento de mi pedido?</strong>
          <p>Te enviaremos la guía de la transportadora vía WhatsApp para que puedas realizar seguimiento.</p>
      </li>
      <li>
          <strong>¿Cuál es su política de devoluciones y cambios?</strong>
          <p>Ofrecemos un plazo de 30 días después de recibir tu pedido para devoluciones, las cuales solo aceptamos por defectos de fábrica.</p>
      </li>
      <li>
          <strong>¿Tienen tiendas físicas donde pueda ver los productos?</strong>
          <p>No, vendemos exclusivamente a través de WhatsApp, Instagram o Facebook.</p>
      </li>
      <li>
          <strong>¿Puedo cancelar o modificar mi pedido después de haberlo realizado?</strong>
          <p>Sí, siempre y cuando no haya sido despachado.</p>
      </li>
      <li>
          <strong>¿Ofrecen garantía en sus productos?</strong>
          <p>Sí, nuestros productos cuentan con los mejores acabados y garantizamos su durabilidad.</p>
      </li>
      <li>
          <strong>¿Cuál es su política de privacidad y seguridad de datos?</strong>
          <p>Nuestra tienda se compromete a proteger la privacidad de nuestros clientes y garantizar la seguridad de sus datos personales. Utilizamos medidas de seguridad físicas, electrónicas y procedimentales para proteger la información que nos proporcionan.</p>
      </li>
      <li>
          <strong>¿Cómo puedo contactar al servicio al cliente en caso de problemas o consultas?</strong>
          <p>Puedes contactarnos a través de cualquiera de nuestros canales de venta, los cuales encontrarás al final de esta página.</p>
      </li>
    </ul>
    `,
  },
];

interface Props {
  panelClassName?: string;
  data?: typeof DEMO_DATA;
}

const AccordionInfo: FC<Props> = ({
  panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6",
  data = DEMO_DATA,
}) => {
  return (
    <div className="w-full rounded-2xl space-y-2.5">
      {/* ============ */}
      {data.map((item, index) => {
        return (
          <Disclosure key={index} defaultOpen={index < 1}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                  <span>{item.name}</span>
                  {!open ? (
                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  ) : (
                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel
                  className={panelClassName}
                  as="div"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      })}

      {/* ============ */}
    </div>
  );
};

export default AccordionInfo;
