import SocialsList1 from "shared/SocialsList1/SocialsList1";
import React from "react";

const Footer: React.FC = () => {
  return (
    <div className="nc-Footer relative py-20 lg:pt-10 lg:pb-10 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid gap-y-10 gap-x-5 sm:gap-x-8 justify-center lg:gap-x-10">
        <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:items-start gap-5" />
      </div>
    </div>
  );
};

export default Footer;
