import React, { FC } from "react";
// import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product } from "data/data";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import ModalQuickView from "./ModalQuickView";
import NotifyAddTocart from "./NotifyAddTocart";
import useCart from "hooks/useCart";

export interface ProductCardProps {
  className?: string;
  data: Product;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data
}) => {  
  const {
    _id,
    name,
    price,
    sku,
    images,
    // slug,
    stock
  } = data;
  const { addCart, getItemQuantity } = useCart()
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const notifyAddTocart = () => {
    addCart({...data, quantity: 1})
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={images[0]}
          qualitySelected={1}
          show={t.visible}
          data={data}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderGroupButtons = () => {
    return (
      // <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
      <div className="absolute bottom-4 inset-x-1 flex justify-center">
        <ButtonPrimary
          className="shadow-lg"
          fontSize="text-m"
          sizeClass="py-3 px-5"
          onClick={() => notifyAddTocart()}
          disabled={getItemQuantity(_id) >= stock }
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">Añadir al carrito</span>
        </ButtonPrimary>
        <ButtonSecondary
          className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-m"
          sizeClass="py-3 px-5"
          onClick={() => setShowModalQuickView(true)}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">Ver</span>
        </ButtonSecondary>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
        data-nc-id="ProductCard"
      >
        {/* <Link to={`/product-detail/${slug}`} className="absolute inset-0"></Link> */}

        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          {/* <Link to={`/product-detail/${slug}`} className="block"> */}
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={images[0]}
              className="object-cover w-full h-full drop-shadow-xl"
            />
          {/* </Link> */}

          {renderGroupButtons()}
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">

          <div>
            <h2
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
            >
              {name}
            </h2>
            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
              {sku} ({stock} Disponibles)
            </p>
          </div>

          <div className="flex justify-between items-end ">
            <Prices price={price} />
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        data={data}
      />
    </>
  );
};

export default ProductCard;
