/* eslint-disable max-len */
// Words, spaces and .
export const name = /^[a-zñ\u00C0-\u017FA-ZÑ .-]+$/;

// Phone number in local or international format
export const phone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

// Email
export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Banking SWIFT code
export const swift = /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/i;

export const regEx = {
  // Strings alphanumeric, it can contain both letters and numbers
  alphaNum: /^[a-z0-9]+$/i,
  // Zip Code can either have 5 digits or 5 digits followed by an hyphen(-) and ends with four digits
  zip: /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/,
};

// url format
export const url =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
