// @ts-ignore
import React from "react";
import { ProductCart } from "data/data";
import { useLocalStorage } from "hooks/useLocalStorage";

interface ContextProps {
  cart: ProductCart[] | [];
  total: number;
  cartQuantity: number;
  addCart: (item: ProductCart) => void;
  removeCart: (item: ProductCart) => void;
  increaseCartQuantity: (id: string) => void;
  decreaseCartQuantity: (id: string) => void;
  getItemQuantity: (id: string) => number;
  clearCart: () => void;
}

interface ProviderProps {
  children?: React.ReactNode;
};

export const CartContext = React.createContext<ContextProps | undefined>({
  cart: [],
  total: 0,
  cartQuantity: 0,
  addCart: () => null,
  removeCart: () => null,
  increaseCartQuantity: () => null,
  decreaseCartQuantity: () => null,
  getItemQuantity: () => 0,
  clearCart: () => null,
});

export const CartProvider: React.FC<ProviderProps> = ({ children }) => {
  let [cart, setCart] = useLocalStorage<ProductCart[]>("shopping-cart", []);

  const cartQuantity = cart.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const total = cart.reduce((acc: number, item) => acc + item.quantity * item.price, 0)

  const getItemQuantity = (id: string) => {
    return cart.find(p => p._id === id)?.quantity || 0
  }

  const addCart = (item: ProductCart) => {    
    setCart((currItems: any[]) => {
      if(currItems.find(p => p._id === item._id) == null) {
        return [...currItems, item];
      } else {
        return currItems.map(p => {
          if (p._id === item._id) {
            return { ...p, quantity: p.quantity + item.quantity }
          } else {
            return p
          }
        })
      }
    });
  };

  const removeCart = (item: ProductCart) => {
    setCart(currItems => {
      return currItems.filter(p => p._id !== item._id)
    })
  }

  const increaseCartQuantity = (id: string) => {
    setCart((currItems: any[]) => {
      if (currItems.find(item => item._id === id) == null) {
        return [...currItems, { id, quantity: 1 }]
      } else {
        return currItems.map(item => {
          if (item._id === id) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }

  const decreaseCartQuantity = (id: string) => {
    setCart((currItems: any[]) => {
      if (currItems.find(item => item._id === id)?.quantity === 1) {
        return currItems.filter(item => item._id !== id)
      } else {
        return currItems.map(item => {
          if (item._id === id) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }

  const clearCart = () => {
    setCart([])
  }

  return (
    <CartContext.Provider value={{ cart, total, cartQuantity, addCart, removeCart, increaseCartQuantity, decreaseCartQuantity, getItemQuantity, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
