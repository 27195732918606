import Label from "components/Label/Label";
import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errors?: string[];
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      children,
      type = "text",
      label,
      errors = [],
      ...args
    },
    ref
  ) => {
    return (
      <div>
        {label && <Label className="text-sm block mb-1.5">{label}</Label>}
        <input
          ref={ref}
          type={type}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 ${className} ${errors?.length > 0 && 'border-red-500'}`}
          {...args}
        />
        {errors?.length > 0 && (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{errors[0]}</span>
        )}
      </div>
    );
  }
);

export default Input;
