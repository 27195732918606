import useCategories from "hooks/useCategories";
import React, { FC } from "react";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";

export interface HeaderFilterSearchPageProps {
  className?: string;
  tabActive: string;
  setTabActive: (tabActive: string) => void
}

const HeaderFilterSearchPage: FC<HeaderFilterSearchPageProps> = ({
  className = "mb-12",
  tabActive,
  setTabActive
}) => {
  const { categories } = useCategories()

  return (
    <div className={`flex flex-col relative ${className}`}>
      <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {[{name: "Todos", _id: ""}, ...categories].map(
            (item, index) => (
              <NavItem
                key={index}
                isActive={tabActive === item._id}
                onClick={() => setTabActive(item._id)}
              >
                {item.name}
              </NavItem>
            )
          )}
        </Nav>
      </div>
    </div>
  );
};

export default HeaderFilterSearchPage;
