import { useEffect, useState } from "react";
import axios from "axios";

function useCategories() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        let { data }: any = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`
        );
        data = data.map((p: any) => ({
          ...p,
          image: p.image.fileUrl,
        }));
        setCategories(data);
        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
        if (axios.isAxiosError(error)) {
          throw new Error(error.response?.data?.message, { cause: "axios" });
        } else {
          throw new Error(error.message, { cause: "function" });
        }
      }
    };

    if (!isCancelled) fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  return {
    isLoading,
    categories,
  };
}

export default useCategories;
