import React, { FC } from 'react'

/**
 * size: fa-xs || fa-sm || fa-lg || fa-2x || fa-3x || fa-5x || fa-7x || fa-10x
 */
export interface SpinnerProps {
  className?: string;
  size?: string;
}

const Spinner: FC<SpinnerProps> = ({className, size}) => {
  return (
    <div className={`text-center ${className}`}>
      <i className={`animate-spin fas fa-circle-notch ${size}`}></i>
    </div>
  )
}

export default Spinner