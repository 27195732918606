import { useState } from "react";
import axios from "axios";

function useOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState();

  const confirmOrder = async (newOrder: any) => {
    setIsLoading(true);
    try {
      let { data }: any = await axios.post(
        `${process.env.REACT_APP_API_URL}/profile/customer/orders`,
        newOrder
      );
      setOrder(data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message, { cause: "axios" });
      } else {
        throw new Error(error.message, { cause: "function" });
      }
    }
  };

  return {
    isLoading,
    order,
    confirmOrder,
  };
}

export default useOrder;
