import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import ProductCard from "components/ProductCard";
import useProducts from "hooks/useProducts";
import Spinner from "shared/Spinner/Spinner";
import useCurrency from "hooks/useCurrency";

export interface PageHomeProps {
  className?: string;
}

const PageHome: FC<PageHomeProps> = ({ className = "" }) => {
  const {currency} = useCurrency()
  let {products, isLoading, category, setCategory} = useProducts({currency})

  return (
    <div className={`nc-PageHome  ${className}`} data-nc-id="PageHome">
      <Helmet>
        <title>Inicio || Tanylu</title>
      </Helmet>

      <div className="container py-10 lg:pb-10 lg:pt-10 space-y-10 lg:space-y-10">
        <main>
          {/* FILTER */}
          <HeaderFilterSearchPage tabActive={category} setTabActive={setCategory}/>
          {isLoading && (<Spinner size="fa-2x" />)}
          {/* LOOP ITEMS */}
          {products.length > 0 && (
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {products.map((item, index) => (
                <ProductCard data={item} key={index} />
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default PageHome;
