import { CartContext } from "context/CartContext";
import { useContext } from "react";

function useCart() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart debe estar dentro de un proveedor AuthContext");
  }
  return context;
}

export default useCart;
