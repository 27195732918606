import React, { FC } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary';

export interface ConfirmOrderPageProps {
    className?: string;
}

const ConfirmOrderPage: FC<ConfirmOrderPageProps> = ({ className = "" }) => {
  return (
    <div className="text-center my-10">
      <i className="far fa-check-circle fa-2x text-green-600"></i>
      <h2>Pedido Confirmado!</h2>
      <div className='rounded-lg my-5 mx-auto shadow-md max-w-md p-6 border border-slate-300'>
        <p>¡Tu pedido ha sido confirmado con éxito!</p>
        <p>Uno de nuestros asesores se pondrá en contacto contigo pronto.</p>
      </div>
      <ButtonPrimary className='mt-3' href='/'>Continuar comprando</ButtonPrimary>
    </div>
  )
}

export default ConfirmOrderPage