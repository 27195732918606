import Label from "components/Label/Label";
import React, { TextareaHTMLAttributes } from "react";

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  errors?: string[];
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className = "", children, rows = 4, label, errors = [], ...args }, ref) => {
    return (
      <div>
        {label && <Label className="text-sm block mb-1.5">{label}</Label>}
        <textarea
          ref={ref}
          className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${className} ${errors?.length > 0 && 'border-red-500'}`}
          rows={rows}
          {...args}
        >
          {children}
        </textarea>
        {errors?.length > 0 && (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{errors[0]}</span>
        )}
      </div>
    );
  }
);

export default Textarea;
