import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import SiteHeader from "containers/SiteHeader";
import PageHome from "containers/PageHome";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import CartPage from "containers/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import { Toaster } from "react-hot-toast";
import ConfirmOrderPage from "containers/ConfirmOrderPage";
import OrderPage from "containers/OrderPage";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/product-detail/:id", component: ProductDetailPage },
  { path: "/account", component: AccountPage },
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/order-confirm", component: ConfirmOrderPage },
  { path: "/order/:id", component: OrderPage }
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
