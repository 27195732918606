import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { ProductCart } from "data/data";
import useCart from "hooks/useCart";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { formatCurrency } from "utils/formatCurrency";

const CartPage = () => {
  const { cart, total, removeCart, increaseCartQuantity, decreaseCartQuantity } = useCart()

  const renderProduct = (item: ProductCart, index: number) => {
    const { _id, images, price, name, sku, quantity, stock, slug } = item;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={images[0]}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to={`/product-detail/${slug}`} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={`/product-detail/${slug}`}>{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <span>{sku}</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <NcInputNumber className="" defaultValue={quantity} max={stock} decreaseQuantity={() => decreaseCartQuantity(_id)} increaseQuantity={() => increaseCartQuantity(_id)}/>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                <NcInputNumber className="relative z-10" defaultValue={quantity} max={stock} decreaseQuantity={() => decreaseCartQuantity(_id)} increaseQuantity={() => increaseCartQuantity(_id)}/>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div></div>
            <button
              type="button"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              onClick={() => removeCart(item)}
            >
              <span>Eliminar</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CartPage">
      <Helmet>
        <title>Shopping Cart || Tanylu</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Carrito de compras
          </h2>          
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
            {cart.map(renderProduct)}
          </div>
          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">
              <h3 className="text-lg font-semibold ">Resumen del pedido</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between pb-4">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {formatCurrency(total)}
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Envio</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {formatCurrency(0)}
                  </span>
                </div>
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Total del pedido</span>
                  <span>{formatCurrency(total)}</span>
                </div>
              </div>
              <ButtonPrimary href="/checkout" className="mt-8 w-full">
                Checkout
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CartPage;
