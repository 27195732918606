import React, { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'shared/Spinner/Spinner';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Prices from 'components/Prices';
import moment from 'moment';
import { formatCurrency } from 'utils/formatCurrency';
import { useReactToPrint } from 'react-to-print'

export interface OrderPageProps {
    className?: string;
}

const OrderPage: FC<OrderPageProps> = ({ className = "" }) => {
  const { id }: any = useParams()
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<any>();
  const [total, setTotal] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        let { data }: any = await axios.get(
          `${process.env.REACT_APP_API_URL}/profile/customer/orders/${id}`
        );
        console.log('data :>> ', data);
        setOrder(data);
        const totalOrder = data.productsOrder.reduce((acc: number, item: any) => acc + item.quantity * item.price, 0)
        setTotal(totalOrder)
        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
        if (axios.isAxiosError(error)) {
          throw new Error(error.response?.data?.message, { cause: "axios" });
        } else {
          throw new Error(error.message, { cause: "function" });
        }
      }
    };

    if (!isCancelled && id !== undefined) fetchData();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  const printDocument = useReactToPrint({
    content: () => contentRef.current,
  })

  const renderProductItem = (product: any, index: number) => {
    const { productImagen, productName, productSku, price, quantity } = product;
    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={productImagen}
            alt={productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium line-clamp-1">{productName}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{productSku || ''}</span>
                </p>
              </div>
              <Prices className="mt-0.5 ml-2" price={price}/>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Cant.</span>
              <span className="inline-block sm:hidden">x</span>
              <span className="ml-2">{quantity}</span>
            </p>          
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold">#{order?._id}</p>
            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>{moment(order?.createdAt).format('LLL')}</span>
              <span className="mx-2">·</span>
              <span className="text-primary-500">{order?.status}</span>
            </p>
          </div>
          <div className="mt-3 sm:mt-0">
            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
            <span>Total</span>
            </p>
            <p className="text-lg font-semibold">{formatCurrency(total)}</p>
          </div>
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {order?.productsOrder?.map(renderProductItem)}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container my-5 lg:my-11">
        {isLoading ? (
          <Spinner size="fa-2x" />
        ) : (
          <div className="space-y-10 sm:space-y-12">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center dark:bg-slate-500/5">
              <h2 className="text-2xl sm:text-3xl font-semibold">Detalle del pedido</h2>              
              <ButtonSecondary
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
                onClick={printDocument}
              >
                Imprimir
              </ButtonSecondary>
            </div>
            {/* style={{padding: '25px'}} */}
            <div ref={contentRef}>
              {renderOrder()}
            </div>
          </div>
        )}
      </main>
    </div>
  )
}

export default OrderPage