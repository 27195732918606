import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import CartDropdown from "./CartDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonBack from "shared/Button/ButtonBack";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const [isHome, setIsHome] = useState(false)
  const navigate = useNavigate();
  const localtion = useLocation();

  useEffect(() => {
    if(localtion.pathname === '/') {
      setIsHome(true)
    } else {
      setIsHome(false)
    }
  }, [localtion])
  

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">
        <div className="lg:flex-1 flex items-center">
          {!isHome && <ButtonBack onClick={() => navigate(-1)} />}
          <Logo className="flex-shrink-0" />
        </div>

        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
          <CurrencyDropdown />
          <CartDropdown />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
