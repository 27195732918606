import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React, { ButtonHTMLAttributes, FC } from 'react'

export interface ButtonProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: () => void;
}

const ButtonBack: FC<ButtonProps> = ({
  className = "flex items-center gap-1 mr-3",
  disabled = false,
  loading,
  type,
  onClick = () => {},
}) => {
  return (
    <button disabled={disabled || loading} className={className} onClick={onClick} type={type}>
        <ChevronLeftIcon title="Volver" className="w-5 h-5"/> 
    {/* <span className='md:inline'>Volver</span> */}
    </button>
  )
}

export default ButtonBack