// @ts-ignore
import React from "react";
import { useLocalStorage } from "hooks/useLocalStorage";

interface ContextProps {
  currency: string;
  changeCurrency: (value: string) => void;
}

interface ProviderProps {
  children?: React.ReactNode;
};

export const CurrencyContext = React.createContext<ContextProps | undefined>({
  currency: 'COP',
  changeCurrency: () => null,
});

export const CurrencyProvider: React.FC<ProviderProps> = ({ children }) => {
  let [currency, setCurrency] = useLocalStorage<string>("currency", 'COP');

  const changeCurrency = (value: string) => {
    setCurrency(value)
  }

  return (
    <CurrencyContext.Provider value={{ currency, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
