import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import AccordionInfo from "./AccordionInfo";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import Policy from "./Policy";
import NotifyAddTocart from "components/NotifyAddTocart";
import useProduct from "hooks/useProduct";
import { useParams } from "react-router-dom";
import Spinner from "shared/Spinner/Spinner";
import useCart from "hooks/useCart";
import useCurrency from "hooks/useCurrency";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { id }: any = useParams()
  const {currency} = useCurrency()
  const { isLoading, product }: any = useProduct(id, currency)
  const {
    name,
    price,
    // sku,
    images,
    // slug
    stock
  } = product || {};
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const { addCart } = useCart()

  const notifyAddTocart = () => {
    addCart({...product, quantity: qualitySelected})
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={images[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          data={product}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {name}
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={price}
            />
          </div>
        </div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              max={stock}
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={notifyAddTocart}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Añadir al carrito</span>
          </ButtonPrimary>
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo />

        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">
          <Policy />
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container my-5 lg:my-11">
        {isLoading ? (
          <Spinner size="fa-2x" />
        ) : (
          <div className="lg:flex">
            {/* CONTENT */}
            <div className="w-full lg:w-[55%] ">
              {/* HEADING */}
              <div className="relative">
                <div className="aspect-w-16 aspect-h-16">
                  <img
                    src={images[0]}
                    className="w-full rounded-2xl object-cover"
                    alt="product detail 1"
                  />
                </div>
              </div>
              {images.length > 1 && (
                <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
                  {images.map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                      >
                        <img
                          src={item}
                          className="w-full rounded-2xl object-cover"
                          alt="product detail 1"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/* SIDEBAR */}
            <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
              {renderSectionContent()}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ProductDetailPage;
